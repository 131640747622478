import { Component } from '@angular/core';

@Component({
  selector: 'app-copy-paste-popup',
  templateUrl: './copy-paste-popup.component.html',
  styleUrl: './copy-paste-popup.component.scss'
})
export class CopyPastePopupComponent {

}
