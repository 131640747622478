import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataServiceService } from '../../../common/data-service.service';
 
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
 
import { CopyPastePopupComponent } from '../../../popups/copy-paste-popup/copy-paste-popup.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent {

  displayedColumns: string[] = [ 'firstName', 'lastName','email','lastActiveOn', 'status'];

  dataSource!: MatTableDataSource<any>;
  displayPipelineView:boolean =  false;

  emptyData = new MatTableDataSource([{ empty: "row" }]);

  usersEmails: string = '';
  errorMessages: string ='';

  showLink:boolean= false;
  

sharebleLink: string  = ''

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  pipelineData:any

  emptyDataCheck = true;

  checkIsAdmin:boolean= false;

  userInfo:any;


  constructor(public dialog: MatDialog, public dataServiceService: DataServiceService,
    private loader: NgxUiLoaderService
  ) {

    this.userInfo = localStorage.getItem('userInfo')


    if (this.userInfo) {

      this.userInfo = JSON.parse(this.userInfo)

      this.checkIsAdmin = this.userInfo.isAdmin

    }
  }

  ngOnInit(){ 
    this.updatePipeLineData()
  }
 
 
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
 
   updatePipeLineData(){
    this.dataServiceService.getAllManageUser().subscribe(
      (res:any) => {


        this.pipelineData = res
        this.pipelineData  =  this.pipelineData?.details.users
    
      this.emptyDataCheck =  this.pipelineData?.length>0 ? true:false;

      if(this.pipelineData){
        this.loader.start()
        setTimeout(() => {

          this.dataUpdate(  this.pipelineData)
          this.loader.stop()
        },2000)
      }
      
    });
  }



dataUpdate(data:any){
  this.dataSource = new MatTableDataSource(data);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
 
}


ngAfterViewInit() {
  if (this.dataSource) {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

clearErrorMsg(){
  this.errorMessages ='';
  this.showLink = false;
}


submitInvites(){
  this.errorMessages = '';
    const emailList = this.usersEmails.split(',');

    for (const email of emailList) {
      const trimmedEmail = email.trim();

      if (!this.isValidEmail(trimmedEmail)) {
        this.errorMessages = `${trimmedEmail} is not a valid email format.`;
      } 
       
    }

    this.isEmailAlreadyExists()

    
    if (this.errorMessages.length === 0) {
      this.errorMessages = '';
      
      this.dataServiceService.inviteUsers({ emailAddresses:this.usersEmails , IPAddress:"192.168.1.4"}).subscribe(
    (response:any) => {
      
     
      
      if(response  && response.details){

        this.sharebleLink =  response.details.inviteURL
        this.showLink = true ;
        this.updatePipeLineData()
        this.usersEmails = '';
        this.paginator.firstPage()
      }

    },
    (error) =>{
       
      if( error.error &&  error.error.outcomes &&  error.error.outcomes.failedValidation ){
        this.errorMessages = error.error.outcomes.messages[0];
      }
      
    }
       )

    }
}


isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  return emailRegex.test(email);
}

isEmailAlreadyExists() {
  const existingEmails = this.usersEmails.split(',').map(e => e.trim());
  const lowerCaseEmails = existingEmails.map(email => email.toLowerCase());

  // Create a Set to filter duplicates
  const uniqueEmailsSet = new Set(lowerCaseEmails);
    // Check if there are duplicates
  const hasDuplicates = uniqueEmailsSet.size < lowerCaseEmails.length;

 
  if (hasDuplicates) {
    this.usersEmails = Array.from(uniqueEmailsSet).join(', ');
  }
}

isInvalidDomain(email: string): boolean {
  const allowedDomains = ['gmail.com', 'yopmail.com']; 
  const domain = email.split('@')[1];
 
  const isAllowedDomain = allowedDomains.includes(domain);
  return !(isAllowedDomain);
}


copyToClipboard(link:any) {
  navigator.clipboard.writeText(link).then(() => {
    console.log('Text copied to clipboard');
  }).catch(err => {
    console.error('Failed to copy: ', err);
  });
}



copyPasteDialog(){
 
  const dialogRef =  this.dialog.open(CopyPastePopupComponent, {
    width:'320px',
    height:'120px',
  });


  // Automatically close the dialog after 3 seconds
  setTimeout(() => {
    dialogRef.close();
  }, 3000);

}

convertTimestampToDate(timestampMs:any) {
      
  timestampMs = timestampMs && timestampMs !== "--"?Number(timestampMs):"--"

  const date =  new Date(timestampMs);
  let  formattedDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();

  if(formattedDate === "Invalid Date Invalid Date"){
   return "--"
  }

  return formattedDate;
}



}
