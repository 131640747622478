import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { PipelineComponent } from './pipeline/pipeline.component';
import { MaterialUiModule } from '../../material-ui.modules';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { FormsModule } from '@angular/forms';
import { PhoneNumberFormatPipe } from '../../common/pipes/phone-number-format.pipe';


@NgModule({
  declarations: [PipelineComponent ,ManageUserComponent , PhoneNumberFormatPipe],
  imports: [
    CommonModule,
    MaterialUiModule,
    DashboardRoutingModule,
    FormsModule 
  ],
  exports:[PipelineComponent ,ManageUserComponent]
})
export class DashboardModule { }
