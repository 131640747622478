
import { createAction, props } from '@ngrx/store';


export const setSearchText = createAction(
  '[Search] Set Search Text',
  props<{ searchText: string }>()
);


export const clearSearchText = createAction('[Search] Clear Search Text');
