import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberFormat'
})
export class PhoneNumberFormatPipe implements PipeTransform {

  transform(phoneNumber: string): string {
     
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

   
    if (cleaned.length === 10) {
      // Standard 10-digit phone number: (XXX) XXX-XXXX
      return cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    } else if (cleaned.length === 11) {
      // International phone number: the first 3 digits are the country code
      const countryCode = cleaned.slice(0, 3); // First 3 digits are the country code
      const localNumber = cleaned.slice(3); // Remaining 8 digits are the local number

      // Format the local number as XXX-XXX-XXXX
      const formattedLocal = localNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');

      return `(${countryCode}) ${formattedLocal}`;
    }

    // If the phone number doesn't have 10 or 11 digits, return the original phone number
    return phoneNumber;
  }
}
