

<!-- Filter it will come later -->

<!-- <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field> -->


  



<div class="pipeline-container"  >


 
  <mat-card appearance="outlined" class="pipeline-card">

    <div class="tabs-group mb-4 mx-3">
        
        <p>PIPELINE  </p>
         <p *ngIf="dataSource.data.length>0 ;else noData" class="mx-1">({{  dataSource.data.length}})</p>
         
         <ng-template #noData>
            <p class="mx-1">(0)</p>
         </ng-template>
       
    </div>


    <mat-card-content class="mt-2" class="table-card"  >
        <!-- *ngIf="pipelineData ;else nofoundTemplate"  -->
        <div class="pipeline-Main" >


            <table mat-table 
            [dataSource]="dataSource.data.length > 0 ? dataSource : emptyData" 
            matSort 
            matSort
            (matSortChange)="announceSortChange($event)"
            >
        
                <!-- Lead Column -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header lead-header" sortActionDescription="Sort by firstName" > FIRST NAME </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.firstName | titlecase}} </td>
                </ng-container>
              
                <!-- Last Name Column -->
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header" sortActionDescription="Sort by lastName"> LAST NAME </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.lastName  | titlecase}} </td>
                </ng-container>
              
                <!-- First Name Column -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header" sortActionDescription="Sort by phone"> PHONE NUMBER </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.phone|phoneNumberFormat}} </td>
                </ng-container>
              
                <!-- Loan Amount Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header" sortActionDescription="Sort by email"> EMAIL ADDRESS </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.email}} </td>
                </ng-container>
              
                <!-- Location Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header" sortActionDescription="Sort by location"> LOCATION </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.location}} </td>
                </ng-container>

                <ng-container matColumnDef="loanAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header" sortActionDescription="Sort by loanAmount"> LOAN AMOUNT </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.loanAmount | currency:'USD':'symbol'}} </td>
                </ng-container>
              
                <!-- Lead Submitted On Column -->
                <ng-container matColumnDef="leadSubmittedOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header"  sortActionDescription="Sort by leadSubmittedOn"> LEAD SUBMITTED ON </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.leadSubmittedOn}} </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header " sortActionDescription="Sort by createdBy"> LEAD SUBMITTED BY </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.createdBy}} </td>
                </ng-container>
              
                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header "  sortActionDescription="Sort by status"> STATUS </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.status}} </td>
                </ng-container>
                
                <ng-container matColumnDef="loanOfficer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header status-header" sortActionDescription="Sort by loanOfficer"> LOANDEPOT POC </th>
                    <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.loanOfficer}} </td>
                </ng-container>

                

                <ng-container matColumnDef="empty-row">
                   
                    <td mat-cell *matCellDef colspan="3"> <h5 class="mt-3"> {{ noLeadText$ | async | titlecase }}</h5></td>
                     
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>


                <tr mat-row *matRowDef="let row; columns: dataSource.data.length > 0 ? displayedColumns : ['empty-row']; let i = index" [ngClass]="{'even-row': i % 2 === 0 && dataSource.data.length >0, 'odd-row': i % 2 !== 0 && dataSource.data.length >0}"></tr>              
            </table>

        </div>

           <div class="paginator">
               
               <mat-paginator   [pageSizeOptions]="[10,15,20]"  class="items-page"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
               </mat-paginator>
           </div>
    


          
        </mat-card-content>
        <!-- <ng-template #nofoundTemplate>
          <div class="d-flex justify-content-center">
              <h5>No records found.</h5>
          </div>
      </ng-template> -->
    </mat-card>


  <div>
    <p class="bottom-placeholder-text">
        This electronic portal is provided to loanDepot Lender Express partners for use in the submission of program-related materials and information.  Unauthorized use is prohibited.  By continuing to use this portal, you certify that you are acting on behalf of a loanDepot Lender Express partner and you agree to abide by the Lender Express program’s terms.    </p>
  </div>
</div>


  
  