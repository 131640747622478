import { createAction, props } from '@ngrx/store';

export const loadPipelineData = createAction('[Pipeline] Load Pipeline Data');
export const loadPipelineDataSuccess = createAction(
  '[Pipeline] Load Pipeline Data Success',
  props<{ data: any }>()
);
export const loadPipelineDataFailure = createAction(
  '[Pipeline] Load Pipeline Data Failure',
  props<{ error: any }>()
);
