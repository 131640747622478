import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { endUrls } from '../../environments/end-urls';
import { AppState } from '../states/app.state';
import { Store } from '@ngrx/store';
import * as PipelineActions from '../states/pipeline/pipeline.actions'
import { setSearchText } from '../states/searchText/search.actions';
@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  private apiUrl = localStorage.getItem('baseUrl');

  constructor(private http: HttpClient ,private store: Store<AppState>) {}

  

  async getPipelineViewData(selectedValue = '', searchTerm = ''): Promise<any> {
   

    let queryString = '';
    if (selectedValue && searchTerm) {
      queryString = `${encodeURIComponent(selectedValue)}=${encodeURIComponent(searchTerm)}`;
    }

    const url = queryString 
      ? `${this.apiUrl}${endUrls.leads}?${queryString}`
      : `${this.apiUrl}${endUrls.leads}`;

    try {
      const response = await this.http.get<any>(url).toPromise();
      
      this.store.dispatch(PipelineActions.loadPipelineDataSuccess({ data: response }))
            
      const resultStatusText = response.details.totalCount === 0  && searchTerm ? 'No results for your search':'No Leads Available';
      
      this.store.dispatch(setSearchText({ searchText: resultStatusText }))
  
      return response;
    } catch (error) {
      console.error('API Error:', error);
      throw error; 
    }
  }


  getLoanOfficers(): Observable<any> {

    return this.http.get(this.apiUrl + endUrls.getLoanOfficers);
  }


  addNewLoad(payload:any): Observable<any> {
 
    return this.http.post(this.apiUrl + endUrls.createLeads,payload, );
  }



  searchApi(selectValue:any , searchTerm:any){
   

    return  this.http.get(this.apiUrl + `endUrls.leads/${selectValue}/${searchTerm}` );
  }




  inviteUsers(payload:any): Observable<any> {
   
    return this.http.post(this.apiUrl + endUrls.inviteUser,payload );
  }

getAllManageUser(){
   
    return  this.http.get(this.apiUrl +  endUrls.manageUsers );
  }

  getCityState(ZipCode:any): Observable<any> {

    let queryString = '';

    if (ZipCode) {
       
      
      if (ZipCode) {
        queryString = `ZipCode=${encodeURIComponent(ZipCode)}&FilterCityByType=false`;
      }
    }
  
    const url =  `${this.apiUrl}${endUrls.zipCodeDetails}?${queryString}`;
      

   return this.http.get(url);


    }

  getLogoutActivity(){
   
    return  this.http.get(this.apiUrl +  endUrls.activityLogout );
  }



}
