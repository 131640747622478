import { ChangeDetectorRef, Component, inject, NgZone, OnChanges, OnInit, Pipe, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TermsServicePopupComponent } from '../../../popups/terms-service-popup/terms-service-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DataServiceService } from '../../../common/data-service.service';
import { AppState } from '../../../states/app.state';
import { select, Store } from '@ngrx/store';
import { selectPipelineData } from '../../../states/pipeline/pipeline.selectors';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { selectSearchText } from '../../../states/searchText/search.selectors';
import { map, Observable } from 'rxjs';
import { SearchTextState } from '../../../states/searchText/search.reducer';

import {LiveAnnouncer} from '@angular/cdk/a11y'




@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent  implements OnInit {

  displayedColumns: string[] = [ 'firstName', 'lastName', 'phone', 'email', 'location', 'loanAmount', 'leadSubmittedOn','createdBy', 'status','loanOfficer'];

  dataSource!: MatTableDataSource<any>;
  displayPipelineView:boolean =  false;

  private _liveAnnouncer = inject(LiveAnnouncer);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  emptyData = new MatTableDataSource([{ empty: "row" }]);

  pipelineData:any;

  noLeadText$!: Observable<string>;
  
  constructor(public dialog: MatDialog , public dataServiceService:DataServiceService ,
    public store: Store<AppState> , private loader: NgxUiLoaderService) {
      this.updatePipeLineData()
      this.noLeadText$ = this.store.pipe(select(selectSearchText))
    }
  
    ngOnInit(){

    this.dataServiceService.getPipelineViewData()


     this.updatePipeLineData();

    
     }
 


 
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
 
   updatePipeLineData(){
    

    this.store.select(selectPipelineData).subscribe(data => {

     
      this.pipelineData = data;
        this.pipelineData  =  this.transformLeadsData(this.pipelineData?.details.leads)
  
        this.noLeadText$ = this.store.pipe(select(selectSearchText))

      if(this.pipelineData){
        this.loader.start()
        setTimeout(() => {

          this.dataUpdate(this.pipelineData)
          this.loader.stop()
        },500)
      }
      
    });
  
         
  
}


dataUpdate(data:any){
  this.dataSource = new MatTableDataSource(data);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
 
}


ngAfterViewInit() {
  if (this.dataSource) {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}



transformLeadsData(leads:any) {
  return leads.map((lead:any) => {
      return {
          ...lead,
           phone: lead.phone.replace(/[^\d]/g, ''), // Remove non-numeric characters and convert to number
          loanAmount: parseFloat(lead.loanAmount.replace(/[^\d.-]/g, '')) // Remove any non-numeric characters (like $) and convert to float
    
        };
  });
}


 

resetSorting() {
  this.sort.active = '';
  this.sort.direction = '';
  this.dataSource.sort = this.sort; // Update the dataSource with the reset sort
  this.sort.sortChange.emit({
    active: '',
    direction: ''
  });

  // Manually trigger Angular change detection to ensure the UI updates
  setTimeout(() => {
    this.dataSource._updateChangeSubscription();
  });
}

announceSortChange(sortState: Sort) {
  
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}





}



