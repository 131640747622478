export const endUrls  = {

    authentication:'api/v1/Authenticate',
    termsAgreementConsent:'api/v1/TermsAgreementConsent',
    leads:'api/v1/Leads',
    createLeads:'api/v1/Lead' ,
    getLoanOfficers:'api/v1/GetLoanOfficers',
    inviteUser:'api/v1/InviteUser',
    manageUsers:'api/v1/users',
    zipCodeDetails:'api/v1/ZipCodeDetails',
    activityLogout:'api/v1/Logout'
}
