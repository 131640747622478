import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SearchTextState } from './search.reducer';

// Selector to get the entire searchText state from AppState
export const getSearchTextState = createFeatureSelector<SearchTextState>('searchText');

// Selector to get the actual searchText value from the state
export const selectSearchText = createSelector(
  getSearchTextState,
  (state: SearchTextState) => state.searchText // Extract searchText from the state
)