import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PipelineState } from './pipeline.reducer';

export const selectPipelineState = createFeatureSelector<PipelineState>('pipeline');

export const selectPipelineData = createSelector(
  selectPipelineState,
  (state: PipelineState) => state.data
);

export const selectPipelineLoading = createSelector(
  selectPipelineState,
  (state: PipelineState) => state.loading
);

export const selectPipelineError = createSelector(
  selectPipelineState,
  (state: PipelineState) => state.error
);
