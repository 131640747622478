<div *ngIf="checkIsAdmin"  class="pipeline-container" >
 
    <mat-card appearance="outlined" class="pipeline-card">
  
      <div class="tabs-group mb-4 mx-3">
        
        <p>USERS  </p>
         <p *ngIf="dataSource.data.length>0 ;else noData" class="mx-1">({{  dataSource.data.length}})</p>
         
         <ng-template #noData>
            <p class="mx-1">(0)</p>
         </ng-template>
       
    </div>


    
      <!-- link generate container -->
      <div class="link-generate-container ">
         
        <div class="row web-mob">
          <div class="col-md-6">
            <input type="text" class="link-generate-input " (keydown.enter)="submitInvites()" (click)="clearErrorMsg()" [(ngModel)]="usersEmails" placeholder="Add user(s) by entering user email(s), separate emails with ','">
          </div>
            
      
          <div  class="col-md-6">
          <button class="btn generate-link-btn " (click)="submitInvites()"
              [disabled]="!usersEmails || usersEmails.trim() === ''"
              [ngClass]="{
                  'generate-link-btn-light': !usersEmails || usersEmails.trim() === '',
                  'generate-link-btn-primary': usersEmails && usersEmails.trim() !== ''
              }"
          >Generate Link</button>
  
        </div>
        </div>

       
      <!-- Tab view  -->
      <div class="tab-view-invite-link">
        <div class="col-md-9">
          <input type="text" class="link-generate-input " (keydown.enter)="submitInvites()" (click)="clearErrorMsg()" [(ngModel)]="usersEmails" placeholder="Add user(s) by entering user email(s), separate emails with ','">
        </div>
          
    
        <div  class="col-md-3">
        <button class="btn generate-link-btn " (click)="submitInvites()"
            [disabled]="!usersEmails || usersEmails.trim() === ''"
            [ngClass]="{
                'generate-link-btn-light': !usersEmails || usersEmails.trim() === '',
                'generate-link-btn-primary': usersEmails && usersEmails.trim() !== ''
            }"
        >Generate Link</button>

      </div>
      </div>
  
    </div>
    
<div>
  <div *ngIf="errorMessages " class="error-messages">
        <p class="mx-4">{{ errorMessages }}</p>
</div>
</div>



<div class="share-link" *ngIf="showLink">
    <p class="copy-text">
     <span  (click)="copyToClipboard(sharebleLink);copyPasteDialog();" > Click to copy the Invite Link</span> <img class="mx-2 cursor-pointer"  src="assets/icons/chain-icon.svg" alt="chain"  (click)="copyToClipboard(sharebleLink);copyPasteDialog();" />
      </p>
</div>
  
      <mat-card-content class="mt-4" class="table-card" >
          <!-- *ngIf="pipelineData ;else nofoundTemplate"  -->
          <div class="pipeline-Main mt-4" >
  
  
              <table mat-table [dataSource]="dataSource.data.length > 0 ? dataSource : emptyData" matSort>
          
                  <!-- First Name  -->
                  <ng-container matColumnDef="firstName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header lead-header" > FIRST NAME </th>
                      <td mat-cell *matCellDef="let row" class="pipeline-table-cell">

                        <p *ngIf="row.firstName; else nullfirstName">
                            {{row.firstName  | titlecase}} 
                              </p>
                              <ng-template #nullfirstName>
                                <p class="px-4 mx-2">__</p>
                              </ng-template>
                     </td>
                  </ng-container>
                
                  <!-- Last Name Column -->
                  <ng-container matColumnDef="lastName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header"> LAST NAME </th>
                      <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> 
                        <p *ngIf="row.firstName; else nullLastName">
                        {{row.lastName  | titlecase}} 
                          </p>
                          <ng-template #nullLastName>
                            <p class="px-4 mx-2">__</p>
                          </ng-template>
                       </td>
                  </ng-container>
                
                  
                  <!-- Loan Amount Column -->
                  <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header"> EMAIL ADDRESS </th>
                      <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.email}} </td>
                  </ng-container>
        
                  <ng-container matColumnDef="lastActiveOn">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header"> LAST ACTIVE </th>
                      <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{convertTimestampToDate( row.lastActiveOn)}} </td>
                  </ng-container>
                
                  
            
                  <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pipeline-table-header status-header"> STATUS </th>
                      <td mat-cell *matCellDef="let row" class="pipeline-table-cell"> {{row.status}} 
                      
                        <img *ngIf="row.isShowInvitationURLLink === true" (click)="copyToClipboard(row.invitationURL);copyPasteDialog();" class="mx-2 cursor-pointer"  src="assets/icons/chain-icon.svg" alt="chain" /> </td>
                  </ng-container>

                  <ng-container matColumnDef="empty-row">

                      <td mat-cell *matCellDef colspan="3"  >  <h5 class="mt-3"> No user available</h5></td>
                       
                  </ng-container>
          
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                 
                  <tr mat-row *matRowDef="let row; columns: dataSource.data.length > 0 ? displayedColumns : ['empty-row']; let i = index" [ngClass]="{'even-row': i % 2 === 0 && dataSource.data.length >0, 'odd-row': i % 2 !== 0 && dataSource.data.length >0}"></tr>              
              
                </table>
              
          
            </div>
          <mat-paginator [pageSizeOptions]="[10,15,20]"  class="items-page"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
          </mat-paginator>
  
            
          </mat-card-content>

          <!-- <ng-template #nofoundTemplate>
            <div class="d-flex justify-content-center">
                <h5>No records found.</h5>
            </div>
        </ng-template> -->
      </mat-card>

    
  </div>